@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

html,
body {
    width: 100%;
    min-height: 100%;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

a,
a:hover {
    color: #333;
    text-decoration: none;
}

body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    color: #000000;
    background: #560BAD url(../../../public/assets/img/bg.png) no-repeat center top;
    background-size: cover;
}

input,
select {
    font-family: 'Poppins', sans-serif;
}

p {
    font-weight: 400;
    font-size: 1em;
    line-height: 140%;
    color: #333333;
}

img {
    max-width: 100%;
}

.main-block {
    max-width: 767px;
    margin: 0 auto;
    padding-top: 65px;
}

.circle-box {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 0 auto 0;
    border-radius: 50%;
    background: #fff;
}

.employee-name {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1em;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 24px;
}

.employee-post {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.85em;
    line-height: 20px;
    text-align: center;
    color: #E0E0E0;
    margin-top: 5px;
    margin-bottom: 24px;
}

.social-block {
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 30px;
}

.social-box {
    background: #EBE0FE;
    border: 0.5px solid #1B1F17;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
}

.social-box img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.btns-block {
    padding: 0 30px;
    margin-top: 35px;
}

.btns-block>div {
    padding: 10px 0;
}

.white-btn {
    border: 1px solid #F2F2F2;
    border-radius: 4px;
    padding: 16px 8px;
    color: #F2F2F2;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 24px;
    background: transparent;
    width: 100%;
}

.footer-link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.footer-link a {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 20px;
    color: #EBE0FE;
    padding: 24px 0;
    display: block;
}

@media (min-width:1199px) {
    body {
        font-size: 18px;
    }

    .social-box {
        width: 54px;
        height: 54px;
    }

    .white-btn {
        padding: 20px 8px;
    }
}

@media(max-height:720px) {
    .footer-link {
        position: relative;
    }
}

/* 404 Page */

.not-found-page {
    text-align: center;
}

.not-found-img img {
    max-height: calc(100vh - 250px);
    margin: 0 auto;
}

.md-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.8em;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 24px;
}

.about-modal .modal-title {
    font-size: 1.2em;
    text-align: center;
    width: 100%;
}

.about-modal .modal-body {
    padding: 30px 40px;

}

.about-modal .modal-body p {
    font-size: 0.8em;
    line-height: 1.8em;
}

.theme-btn {
    background: #507DD5;
    color: #fff;
    padding: 8px 25px;
    font-size: 1em;
}

.theme-btn:hover {
    background: #507DD5;
    color: #fff;
}

.mt-30 {
    margin-top: 30px !important;
}

/* From */
.contact-form .form-group {
    margin-bottom: 24px;
}

.contact-form label {
    font-family: Poppins;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.4em;
    color: #333;
    margin-bottom: 8px;
    position: relative;
    padding-right: 15px;
}

label.asterisks:after {
    content: "*";
    position: absolute;
    right: 0;
    top: 0;
    color: red;
}

.contact-form .form-control {
    background: #F7F7FD;
    border-radius: 4px;
    height: 54px;
    border: 1.5px solid #f7f7fd;
    font-size: 0.85em;
}

.contact-form .form-control:focus {
    box-shadow: none;
}

.contact-form textarea.form-control {
    height: 120px;
    line-height: normal;
    resize: none;
}

.contact-form .form-control::-webkit-input-placeholder {
    opacity: 0.5;
}

.contact-form .form-control::-moz-placeholder {
    opacity: 0.5;
}

.contact-form .form-control:-ms-input-placeholder {
    opacity: 0.5;
}

.contact-form .form-control:-moz-placeholder {
    opacity: 0.5;
}
.ml-15 {
    margin-left: 15px !important;
}
.phone-field .separate-dial-code {
    width: 100%;
}
.phone-field .intl-tel-input .selected-flag {
    width: 105px!important;
    align-items: center!important;
    display: flex!important;
    padding: 0 7px!important;
}
.phone-field .intl-tel-input input[type=tel] {
    padding-left: 126px !important;
}
.phone-field .intl-tel-input .selected-dial-code {
    padding-left: 5px !important;
}
.phone-field .intl-tel-input .flag-container .selected-flag,
.phone-field .intl-tel-input .flag-container:hover .selected-flag {
    background: #e7e7f7 !important;
    border-radius: 4px 0 0 4px;
}
label.error {
    font-size: .9em;
    line-height: 1.4em;
    font-weight: 400;
    color: #f72828;
    margin-top: 12px;
}
.theme-dark-btn {
    background: #B5179E;
    border-radius: 4px;
    padding: 12px 30px;
    font-weight: 600;
    font-size: .9em;
    line-height: 20px;
    color: #fff;
    border: solid 2px #B5179E;
    display: inline-block;
    transition: all .5s ease-in-out 0s;
}
.theme-dark-btn:hover {
    background: transparent !important;
    color: #B5179E !important;
    border: solid 2px #B5179E !important;
}
.theme-dark-transparent-btn {
    background: transparent;
    border-radius: 4px;
    padding: 12px 30px;
    font-weight: 600;
    font-size: .9em;
    line-height: 20px;
    color: #B5179E;
    border: solid 2px #B5179E;
    display: inline-block;
    transition: all .5s ease-in-out 0s;
}
.theme-dark-transparent-btn:hover {
    background: #B5179E !important;
    color: #fff !important;
    border: solid 2px #B5179E !important;
}